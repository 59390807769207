<!--
 * @Description: 
 * @Author: YangXin
 * @Date: 2024-08-11 09:53:23
 * @listEditTime: Do not edit
-->
<template>
  <el-dropdown trigger="click">
    <span class="el-dropdown-link">
      <el-button>编辑</el-button>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        :icon="item.icon"
        v-for="(item,index) in dropdownData"
        :key="index"
        @click.native="editUrlIndex(block, blackChildrenIndex)"
        >{{ item.name }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
    data(){
        return {}
    }
}
</script>

<style>
</style>

